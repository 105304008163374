<script setup>
import { onMounted, ref, computed } from 'vue'
import { useAnimalStore } from '@/stores/animal.js'
import { useDrugStore } from '@/stores/drug.js'
import AnimalSelector from '@/components/AnimalSelector.vue'
import WeightSelector from '@/components/WeightSelector.vue'
import DosageOutput from '@/components/DosageOutput.vue'
import { version } from '../../package.json'

// Constructing the data object from data.json
let data = ref(null)
onMounted(async () => {
  try {
    const response = await fetch('/api/data.json')
    data.value = await response.json()
    await drugStore.createDrugs()
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
})

const animalStore = useAnimalStore()
const drugStore = useDrugStore()
const currentAnimal = computed(() => animalStore.currentAnimal)
let showDrugList = ref(false)
</script>

<template>
  <div
    v-if="data"
    class="w-full lg:w-1/2 flex flex-col items-center justify-center mx-auto bg-blue-100 p-4 space-y-2"
  >
    <header>
      <Transition name="bounce">
        <img
          v-if="!showDrugList"
          alt="Mivet logo"
          class="w-48 mx-auto py-6"
          src="@/assets/MivetLogo.svg"
          width="300"
        />
      </Transition>
      <div v-if="!showDrugList" class="text-xs text-center sticky">v{{ version }}</div>
    </header>
    <!--    Animal Selector-->
    <div class="w-full">
      <Transition name="bounce">
        <AnimalSelector :appData="data" />
      </Transition>
    </div>

    <!--    Weight Selector-->
    <div v-for="animal in data.globals.animals" :key="animal.species" class="w-full">
      <Transition name="bounce">
        <WeightSelector
          v-if="currentAnimal.species === animal.species"
          :animal="animal"
          :showDrugList="showDrugList"
          @updateShowDrugList="showDrugList = $event"
        />
      </Transition>
    </div>

    <!--    Calculated Dosage-->
    <div v-for="animal in data.globals.animals" :key="animal.species" class="w-full">
      <DosageOutput
        :animal="animal"
        :showDrugList="showDrugList"
        v-if="currentAnimal.species === animal.species"
      />
    </div>
  </div>
</template>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
