<script setup>
import { computed } from 'vue'
import { useAnimalStore } from '@/stores/animal.js'
import AnimalIcon from '@/components/icons/AnimalIcon.vue'

const animalStore = useAnimalStore()
let currentAnimal = computed(() => animalStore.currentAnimal)
const updateAnimal = animalStore.updateAnimal

defineProps({
  appData: {
    type: Object,
    required: true
  }
})
</script>

<template>
  <div class="flex flex-row flex-wrap justify-center items-center w-full gap-3">
    <label class="cursor-pointer" v-for="animal in appData.globals.animals" :key="animal.species">
      <template v-if="currentAnimal.species === null">
        <div class="bg-white rounded-2xl p-4 border-gray-300 border-2 hover:border-blue-300">
          <AnimalIcon :species="animal.species" iconClass="w-28 h-28" />
          <label class="sr-only" :for="animal.species">{{ animal.species }}</label>
          <input
            type="checkbox"
            v-model="currentAnimal.species"
            :value="animal.species"
            @change="updateAnimal({ species: animal.species })"
            class="hidden"
          />
        </div>
      </template>
    </label>
  </div>
</template>

<style scoped></style>
