<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useAnimalStore } from '@/stores/animal.js'
import { useDrugStore } from '@/stores/drug.js'
import MethodIcon from '@/components/icons/MethodIcon.vue'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels
} from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import ImageLoadingSkeleton from '@/components/icons/ImageLoadingSkeleton.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import DosageCalculator from '@/components/DosageCalculator.vue'

const animalStore = useAnimalStore()
const drugStore = useDrugStore()

let props = defineProps({
  animal: {
    type: Object,
    default: () => ({})
  },
  transitionName: {
    type: String,
    default: 'bounce'
  },
  showDrugList: {
    type: Boolean,
    default: false
  }
})

let currentAnimal = computed(() => animalStore.currentAnimal)
let drugs = computed(() => drugStore.drugs)
let isLoading = ref(true)

watch(
  [
    () => animalStore.currentAnimal.initialWeight,
    () => animalStore.currentAnimal.initialWeightUnit
  ],
  ([newWeight, newUnit], [oldWeight, oldUnit]) => {
    // If the weight has changed, or the unit has changed from 'g', recalculate final weight
    if (newWeight !== oldWeight || newUnit !== oldUnit) {
      animalStore.calculateFinalWeight()
    }
  }
)

let selectedConcentration = ref(0)
onMounted(() => {
  if (drugs.value.length > 0 && drugs.value[0].concentrations.length > 0) {
    selectedConcentration.value = drugs.value[0].concentrations[0]
  } else {
    selectedConcentration.value = 1
  }
})

const createStage = (drug, animal) => {
  drugStore.createStage(drug, animal)
}

const updateStage = (name, stage) => {
  drugStore.updateStage(name, stage)
}

const createAdjustedRate = (drug, stage, animal) => {
  drugStore.createAdjustedRate(drug, stage, animal)
}

const updateAdjustedRate = (name, stage, animal) => {
  drugStore.updateAdjustedRate(name, stage, animal)
}
</script>

<template>
  <div
    v-if="
      props.showDrugList && currentAnimal.initialWeight != null && currentAnimal.initialWeight > 0
    "
    class="mt-4"
  >
    <div class="border-gray-300 bg-white border-2 rounded-2xl divide-y">
      <template v-for="drug in drugs" :key="drug.name">
        <div
          v-auto-animate
          v-if="drug.animals[currentAnimal.species] === true"
          class="group mx-auto w-full first:rounded-t-2xl last:rounded-b-2xl"
        >
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              @click="
                createStage(drug.name, currentAnimal.species),
                  createAdjustedRate(drug.name, drug.currentStage, currentAnimal.species)
              "
              class="flex w-full justify-between p-4 text-left text-base text-gray-900 bg-white group-first:disclosure-button-top group-last:disclosure-button-bottom hover:bg-gray-100 hover:transition aria-expanded:bg-gray-100 aria-expanded:font-bold aria-expanded:!rounded-b-none"
            >
              <p class="font-medium">
                <MethodIcon :method="drug.method" iconClass="w-6 h-6 inline mr-2" />
                {{ drug.name }}
                <template v-if="drug.concentrations">
                  <div class="inline text-sm">
                    <span>(</span>
                    <template v-for="(concentration, key, index) in drug.concentrations" :key="key">
                      <span v-if="index === 0">{{ key }}</span>
                      <span v-else>, {{ key }}</span>
                    </template>
                    <span>)</span>
                  </div>
                </template>
              </p>
              <ChevronRightIcon
                class="h-6 w-6 transition-transform font-bold"
                :class="open && 'rotate-90 transition-transform'"
              />
            </DisclosureButton>
            <DisclosurePanel class="text-gray-500 px-4 py-6 space-y-6">
              <TabGroup vertical :defaultIndex="0">
                <TabList
                  class="flex flex-col md:flex-row w-full md:w-fit mx-auto rounded-2xl md:rounded-full bg-gray-100 p-1"
                >
                  <template v-for="(key, stage) in drug.dosageRate" :key="stage">
                    <Tab
                      v-auto-animate
                      v-if="drug.dosageRate[stage][currentAnimal.species] != null"
                      as="template"
                    >
                      <button
                        :class="[
                          ' w-full md:w-auto rounded-xl md:rounded-full py-2.5 px-4 font-medium leading-5',
                          drug.currentStage === stage
                            ? 'bg-white text-blue-700 shadow'
                            : 'text-gray-700 hover:bg-white/[0.12]'
                        ]"
                        @click="
                          updateStage(drug.name, stage),
                            updateAdjustedRate(drug.name, stage, currentAnimal.species)
                        "
                      >
                        {{ stage }}
                      </button>
                    </Tab>
                  </template>
                </TabList>
                <TabPanels class="mt-2">
                  <TabPanel
                    v-for="(value, stage) in drug.dosageRate"
                    :key="stage"
                    :class="['bg-white']"
                  >
                    <div class="text-center space-y-4">
                      <DosageCalculator
                        :animalWeight="currentAnimal.finalWeight"
                        :weightUnit="currentAnimal.finalWeightUnit"
                        :method="drug.method"
                        :concentrations="drug.concentrations"
                        :selectedConcentration="selectedConcentration"
                        :fixedDosageRate="
                          drug.dosageRate[drug.currentStage][currentAnimal.species]['rate']
                        "
                        :hasRange="
                          drug.dosageRate[drug.currentStage][currentAnimal.species]['range'] != null
                        "
                        :rangeMin="drug.dosageRate[drug.currentStage][animal.species]['range']?.min"
                        :rangeMax="drug.dosageRate[drug.currentStage][animal.species]['range']?.max"
                        :rangeStep="
                          drug.dosageRate[drug.currentStage][animal.species]['range']?.step
                        "
                        :adjustedDosageRate="
                          drug.dosageRate[drug.currentStage][currentAnimal.species]['adjusted']
                        "
                        :dosageUnit="drug.dosageUnit"
                        :dosageRateUnit="drug.dosageRateUnit"
                        :dosesPerDay="drug.dosesPerDay"
                        :dispensed="drug.dispensed"
                        :tabletSplitInto="drug.tabletSplitInto"
                      />
                      <div v-if="drug.image?.url !== undefined">
                        <div v-if="isLoading">
                          <ImageLoadingSkeleton />
                        </div>
                        <img
                          :src="drug.image.url"
                          class="w-full"
                          :alt="drug.name"
                          @load="isLoading = false"
                        />
                      </div>
                      <template v-if="drug.notes !== undefined">
                        <AlertMessage
                          :alertTrigger="
                            drug.notes['General'] !== undefined ||
                            drug.notes[animal.species] !== undefined
                          "
                          class="text-start"
                        >
                          <template v-if="drug.notes['General'] !== undefined">
                            <p>
                              <span class="font-bold">All animals:</span>
                              {{ drug.notes['General'] }}
                            </p>
                          </template>
                          <template v-if="drug.notes[animal.species] !== undefined">
                            <p>
                              <span class="font-bold">For {{ animal.species }}s:</span>
                              {{ drug.notes[animal.species] }}
                            </p>
                          </template>
                        </AlertMessage>
                      </template>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </template>
    </div>
  </div>
</template>
