<script setup>
import { computed, ref } from 'vue'
import { useAnimalStore } from '@/stores/animal.js'
import { useDrugStore } from '@/stores/drug.js'
import AlertMessage from '@/components/AlertMessage.vue'
import AnimalIcon from '@/components/icons/AnimalIcon.vue'

const animalStore = useAnimalStore()
const drugStore = useDrugStore()
let currentAnimal = computed(() => animalStore.currentAnimal)
let noAnimalWeight = ref(false)
let showDrugList = ref(false)

let props = defineProps({
  animal: {
    type: Object,
    default: () => ({})
  },
  transitionName: {
    type: String,
    default: 'bounce'
  }
})

const emit = defineEmits(['updateShowDrugList'])

const showDrugs = () => {
  if (
    currentAnimal.value.initialWeight === 0 ||
    currentAnimal.value.initialWeight === null ||
    !currentAnimal.value.initialWeight
  ) {
    noAnimalWeight.value = true
    showDrugList.value = false
  } else {
    noAnimalWeight.value = false
    showDrugList.value = true
  }
  emit('updateShowDrugList', showDrugList.value)
}

const resetAnimal = () => {
  animalStore.resetAnimal()
  drugStore.deleteDrugs()
  drugStore.createDrugs()
  showDrugs()
}
</script>

<template>
  <div
    class="flex flex-col items-center space-y-4 py-6 bg-white border-gray-300 border-2 rounded-2xl"
  >
    <AnimalIcon :species="animal.species" iconClass="w-36 h-36" />
    <div class="space-y-4">
      <Transition :name="props.transitionName">
        <AlertMessage :alertTrigger="noAnimalWeight"> Please enter a weight first </AlertMessage>
      </Transition>
      <div class="rounded-md">
        <label for="species" class="sr-only"> Enter the {{ animal.species }}'s weight: </label>
        <div class="flex items-center border-2 border-gray-300 rounded-md">
          <input
            v-model="currentAnimal.initialWeight"
            type="number"
            min="0"
            step="0.01"
            :placeholder="animal.species + '\'s weight'"
            inputmode="decimal"
            name="weight"
            id="weight"
            class="flex-auto px-4 py-2 bg-transparent border-none focus:ring-0 focus:outline-none rounded-r-md text-end"
          />
          <label for="weight-unit" class="sr-only">Weight Unit</label>
          <select
            v-model="currentAnimal.initialWeightUnit"
            id="weight-unit"
            name="weight-unit"
            class="inline-flex bg-transparent h-full items-center text-gray-900 border-l-2 px-3 py-2 border-gray-300"
          >
            <option selected>kg</option>
            <option>g</option>
          </select>
        </div>
      </div>
      <div class="flex flex-col md:flex-row gap-2 w-full md:w-auto">
        <button
          class="btn btn-success bg-green-700 text-nowrap hover:bg-green-600 px-4 py-2 rounded-full text-white text-gray uppercase font-normal w-full"
          @click="showDrugs"
        >
          List drugs
        </button>
        <button
          class="btn btn-warning bg-yellow-500 text-nowrap hover:bg-yellow-400 px-4 py-2 rounded-full text-white text-gray uppercase font-normal"
          @click="resetAnimal"
        >
          Change animal
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
