<script setup>
import { computed, ref } from 'vue'
import DosageDispenser from '@/components/DosageDispenser.vue'

let props = defineProps({
  animalWeight: Number,
  weightUnit: String,
  method: String,
  concentrations: Object,
  selectedConcentration: Number,
  fixedDosageRate: Number,
  hasRange: Boolean,
  rangeMin: {
    type: Number,
    default: undefined
  },
  rangeMax: {
    type: Number,
    default: undefined
  },
  rangeStep: {
    type: Number,
    default: undefined
  },
  adjustedDosageRate: Number,
  dosageUnit: String,
  dosageRateUnit: String,
  dosesPerDay: Number,
  tabletSplitInto: Number,
  dispensed: Boolean
})

let fixedDosageAmount = computed(() => {
  if (props.concentrations && props.method !== 'Oral') {
    return Number(
      (
        (props.fixedDosageRate * props.animalWeight) /
        Object.values(props.concentrations)[0].amount
      ).toFixed(2)
    )
  } else {
    return Number((props.fixedDosageRate * props.animalWeight).toFixed(2))
  }
})

let adjustedDosageRate = ref(props.fixedDosageRate)
let adjustedDosageAmount = computed(() => {
  if (props.concentrations) {
    return Number(
      (
        (adjustedDosageRate.value * props.animalWeight) /
        Object.values(props.concentrations)[0].amount
      ).toFixed(2)
    )
  } else {
    return Number((adjustedDosageRate.value * props.animalWeight).toFixed(2))
  }
})

let hasConcentrations = props.concentrations !== undefined
</script>

<template>
  <div class="space-y-8">
    <DosageDispenser
      v-if="method === 'Oral' || dispensed === true"
      :dosage="hasRange === true ? adjustedDosageAmount : fixedDosageAmount"
      :concentrations="concentrations"
      :tabletSplitInto="tabletSplitInto"
      :dosesPerDay="dosesPerDay"
    />
    <div v-if="hasRange">
      <p class="font-black" :class="method === 'Injection' ? 'text-3xl' : 'text-xl'">
        {{ adjustedDosageAmount }} {{ dosageUnit }} <span v-if="method === 'Oral'">per dose</span>
      </p>
      <p class="text text-gray-400">
        {{ animalWeight }} {{ weightUnit }} @ {{ adjustedDosageRate }} {{ dosageRateUnit }}
        <span v-if="hasConcentrations && method !== 'Oral'"
        >@ {{ Object.keys(concentrations)[0] }}</span
        >
      </p>
      <h2 class="text-xl font-bold mb-4">Adjust dosage (slide or input)</h2>
      <div class="text-center mb-4 space-x-4">
        <label for="adjusted-dosage-direct" class="sr-only">
          Enter the adjusted dosage directly:
        </label>
        <input
          v-model="adjustedDosageRate"
          type="number"
          :min="rangeMin"
          :max="rangeMax"
          :step="rangeStep"
          inputmode="decimal"
          name="adjusted-dosage-direct"
          id="adjusted-dosage-direct"
          class="flex-auto px-4 py-2 bg-transparent border-2 focus:ring-0 focus:outline-none rounded-m font-bold text-2xl"
        />
        <span class="font-bold text-xl">{{ dosageRateUnit }}</span>
      </div>
      <div>
        <label for="adjusted-dosage-slide" class="sr-only">
          Enter the adjusted dosage via slider:
        </label>
        <input
          v-auto-animate
          name="adjusted-dosage-slide"
          id="adjusted-dosage-slide"
          type="range"
          v-model="adjustedDosageRate"
          :min="rangeMin"
          :max="rangeMax"
          :step="rangeStep"
          class="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-8 [&::-webkit-slider-thumb]:w-8 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-blue-500"
        />
      </div>
      <div class="flex w-full justify-between px-2 text-xl font-bold">
        <span>{{ rangeMin }}</span>
        <span>{{ rangeMax }}</span>
      </div>
    </div>
    <div v-else>
      <p class="font-black" :class="method === 'Injection' ? 'text-3xl' : 'text-xl'">
        {{ fixedDosageAmount }} {{ dosageUnit }} <span v-if="method === 'Oral'">per dose</span>
      </p>
      <p class="text text-gray-400">
        {{ animalWeight }} {{ weightUnit }} @ {{ fixedDosageRate }} {{ dosageRateUnit }}
        <span v-if="hasConcentrations && method !== 'Oral'"
        >@ {{ Object.keys(concentrations)[0] }}</span
        >
      </p>
    </div>
  </div>
</template>

<style scoped></style>
