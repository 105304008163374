import './assets/main.css'
import { createApp } from 'vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://ef73a5b2dfc755ee1f36527698de6bf5@o4506550739992576.ingest.us.sentry.io/4507694787919872',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://dc.mivet.au'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
})

app.use(createPinia())
app.use(router)
app.use(autoAnimatePlugin)
app.mount('#app')
