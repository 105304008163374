import { defineStore } from 'pinia'

export const useAnimalStore = defineStore({
  id: 'animal',
  state: () => ({
    currentAnimal: {
      species: null,
      initialWeight: null,
      initialWeightUnit: 'kg',
      finalWeight: null,
      finalWeightUnit: 'kg'
    }
  }),
  actions: {
    updateAnimal(animal) {
      for (let key in animal) {
        if (Object.prototype.hasOwnProperty.call(animal, key)) {
          this.currentAnimal[key] = animal[key]
        }
      }
    },
    resetAnimal() {
      this.currentAnimal = {
        species: null,
        initialWeight: null,
        initialWeightUnit: 'kg',
        finalWeight: null,
        finalWeightUnit: 'kg'
      }
    },
    calculateFinalWeight() {
      if (this.currentAnimal.initialWeightUnit === 'g') {
        // console.log('Unit is g')
        this.currentAnimal.finalWeight = this.currentAnimal.initialWeight / 1000
      } else {
        // console.log('Unit is kg')
        this.currentAnimal.finalWeight = this.currentAnimal.initialWeight
        this.currentAnimal.finalWeightUnit = 'kg'
      }
    }
  }
})
