import { defineStore } from 'pinia'

export const useDrugStore = defineStore({
  id: 'druglist',
  state: () => ({
    drugs: []
  }),
  actions: {
    async createDrugs() {
      try {
        const response = await fetch('/api/data.json')
        // if (!response.ok) {
        //   throw new Error('Failed to load drugs data')
        // }
        const data = await response.json()
        const drugsData = data.drugs.sort((a, b) => a.name.localeCompare(b.name))
        drugsData.forEach((drugDetail) => {
          const existsInStore = this.drugs.some((d) => d.name === drugDetail.name)
          if (!existsInStore) {
            this.drugs.push(drugDetail)
          }
        })
      } catch (error) {
        console.error('Error loading drugs:', error)
      }
    },
    deleteDrugs() {
      this.drugs = []
    },
    readDrugIndex(drugName) {
      return this.drugs.findIndex((d) => d.name === drugName)
    },
    createStage(drugName, animal) {
      const drug = this.drugs.find((d) => d.name === drugName)
      const stage = Object.keys(drug.dosageRate).find(
        (stage) => drug.dosageRate[stage][animal] !== undefined
      )

      if (!drug.currentStage) {
        drug.currentStage = stage
        // console.log('The initial stage is:', drug.currentStage)
      }
    },
    updateStage(drugName, stage) {
      const drug = this.drugs.find((d) => d.name === drugName)
      if (drug.currentStage !== stage) {
        drug.currentStage = stage
        // console.log('The updated stage is:', drug.currentStage)
      }
    },
    createAdjustedRate(drugName, stage, animal) {
      const drugIndex = this.readDrugIndex(drugName)
      const initialRate = this.drugs[drugIndex].dosageRate[stage][animal]
      if (drugIndex !== -1 && !initialRate.adjusted) {
        initialRate.adjusted = initialRate.rate
        // console.log('The initial adjusted rate is:', this.drugs[drugIndex].dosageRate[stage][animal].adjusted)
      }
    },
    updateAdjustedRate(drugName, stage, animal) {
      const drugIndex = this.readDrugIndex(drugName)
      const initialRate = this.drugs[drugIndex].dosageRate[stage][animal]
      if (drugIndex !== -1 && !initialRate.adjusted) {
        initialRate.adjusted = initialRate.rate
        // console.log('The initial adjusted rate is:', this.drugs[drugIndex].dosageRate[stage][animal].adjusted)
      }
    }
  }
})
