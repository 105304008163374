<script setup>
import { ref, computed } from 'vue'
let props = defineProps({
  dosage: Number,
  concentrations: Object,
  tabletSplitInto: Number,
  dosesPerDay: Number
})

let selectedConcentration = ref(null)
let daysToDispense = ref(0)

let dispenseTabletsPerDose = computed(() => {
  return Number(
    Math.round(
      (props.dosage / selectedConcentration.value.amount) *
        props.tabletSplitInto
    ) / props.tabletSplitInto
  )
})
</script>

<template>
  <div class="space-y-8">
    <div class="flex justify-center space-x-4 text-xl">
      <div>
        <span class="font-bold"> Concentration: </span>
        <select
          v-model="selectedConcentration"
          id="concentration"
          name="concentration"
          class="border-dotted border-2 p-1"
        >
          <option value="0" selected>Select</option>
          <template v-for="concentration in concentrations" :key="concentration.amount">
            <option :value="concentration" :selected="true">
              {{ concentration.amount }} {{ concentration.unit }}
            </option>
          </template>
        </select>
      </div>
      <div>
        <span class="font-bold"> Days: </span>
        <select
          v-model="daysToDispense"
          name="daysToDispense"
          id="daysToDispense"
          class="border-dotted border-2 p-1"
        >
          <template v-for="day in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]" :key="day">
            <option :value="day">{{ day }}</option>
          </template>
        </select>
      </div>
    </div>
    <div v-if="selectedConcentration !== null && daysToDispense > 0" class="text-2xl p-4 border bg-gray-100">
      <p>
        <span class="font-bold capitalize">Label: </span>
        {{ dispenseTabletsPerDose }}
        tablet{{ dispenseTabletsPerDose === 1 ? '' : 's' }},
        {{ dosesPerDay === 1 ? 'once' : dosesPerDay === 2 ? 'twice' : dosesPerDay + ' times' }} per
        day
        <span v-if="daysToDispense > 0"
          >for {{ daysToDispense }} day{{ daysToDispense > 1 ? 's' : '' }}
        </span>
      </p>
      <p>
        <span class="font-bold">Bill for: </span>
        {{ Math.round(dispenseTabletsPerDose * dosesPerDay * daysToDispense) }} x
        {{ selectedConcentration.amount }} {{ selectedConcentration.unit }} tablet{{
          dispenseTabletsPerDose * dosesPerDay * daysToDispense > 1 ? 's' : ''
        }}
      </p>
    </div>
  </div>
</template>

<style scoped></style>
